<template>
  <main>
    <section class="section">
      <div class="container2">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
            <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
            <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
            <sqr-crumb name="relation-policies" :bid="bid" :rid="rid" label="breadcrumb_relation_policies"/>
            <sqr-crumb name="relation-policy-add" :bid="bid" :rid="rid" label="breadcrumb_relation_policies_add"/>
          </ul>
        </nav>
      </div>
    </section>
    <section class="section" v-if="record">
      <div class="container">
        <div class="columns">
          <div class="column is-4-desktop is-6-tablet is-12-mobile">
            <sqr-input-select
              class="field"
              label="policy_insurer"
              :value="record.insurerId"
              :options="insurers"
              value-key="id"
              label-key="company"
              @change="insurerSet"
            />
            <sqr-input-select
              class="field"
              label="policy_branch"
              :value="record.branchId"
              :options="branches"
              value-key="id"
              label-key="abrev"
              @change="branchSet"
            />
            <sqr-input-text
              class="field"
              label="policy_number"
              :value="record.number"
              @change="fieldSet({ field: 'number', value: $event })"
            />
          </div>
          <div class="column is-4-desktop is-6-tablet is-12-mobile">
            <sqr-input-date
              class="field"
              label="policy_date_start"
              :value="record.dateStart"
              @change="fieldSet({ field: 'dateStart', value: $event })"
            />
            <sqr-input-date
              class="field"
              label="policy_date_until"
              :value="record.dateEnd"
              @change="fieldSet({ field: 'dateEnd', value: $event })"
            />
            <sqr-input-number
              class="field"
              label="policy_premium"
              :value="record.premium"
              @change="fieldSet({ field: 'premium', value: $event })"
            />
          </div>
          <div class="column is-4-desktop is-6-tablet is-12-mobile">
            <sqr-input-textarea
              class="field"
              label="policy_note"
              :value="record.note"
              @change="fieldSet({ field: 'note', value: $event })"
            />
          </div>
        </div>
        <div class="field">
          <div class="buttons is-pulled-right">
            <sqr-button
              label="buttons_cancel"
              @click="$router.back()"
              color="light"
              size="medium"
            />
            <sqr-button
              label="buttons_save"
              @click="saveAndGo()"
              color="primary"
              size="medium"
              :is-loading="saving"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import relation from './relation';

import SqrInputSelect from '@/sqrd/SqrInputSelect';
import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputDate from '@/sqrd/SqrInputDate';
import SqrInputNumber from '@/sqrd/SqrInputNumber';
import SqrInputTextarea from '@/sqrd/SqrInputTextarea';

export default {
  name: 'RelationPolicyAdd',
  mixins: [relation],
  components: {
    SqrInputSelect,
    SqrInputText,
    SqrInputDate,
    SqrInputNumber,
    SqrInputTextarea
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('policy', ['record', 'saving']),
    ...mapGetters('insurers', {
      insurers: 'records',
      insurersLoading: 'loading'
    }),
    ...mapGetters('branches', {
      branches: 'records',
      branchesLoading: 'loading'
    }),
    title() {
      return this.$t('relation_policy_add_title', this.relation);
    },
    subtitle() {
      return this.$t('relation_policy_add_subtitle', this.relation);
    }
  },
  mounted() {
    this.init({
      path: ['brokers', this.bid],
      record: {
        insurerName: '',
        branchName: '',
        relationName: '',
        archived: false
      }
    });
    this.insSub({path: ['brokers', this.bid]});
    this.braSub({path: ['brokers', this.bid]});
  },
  methods: {
    ...mapMutations('policy', ['fieldSet']),
    ...mapActions('policy', ['init', 'save']),
    ...mapActions('insurers', {insSub: 'sub'}),
    ...mapActions('branches', {braSub: 'sub'}),
    insurerSet(id, opt) {
      this.fieldSet({ field: 'insurerId', value: id });
      this.fieldSet({ field: 'insurerName', value: opt.company });
    },
    branchSet(id, opt) {
      this.fieldSet({ field: 'branchId', value: id });
      this.fieldSet({ field: 'branchName', value: opt.name });
      this.fieldSet({ field: 'branchAbrev', value: opt.abrev });
    },
    ...mapActions('policiesIndex', ['reindex']),
    async saveAndGo() {
      try {
        this.fieldSet({field: 'relationId', value: this.rid});
        this.fieldSet({field: 'relationName', value: this.relation.name});
        const res = await this.save({});
        const { bid, rid} = this.$route.params;
        const pid = res.id;
        await this.reindex({bid, id: pid, doc: this.policy});
        this.$router.replace({
          name: 'relation-policy',
          params: { bid, rid, pid }
        });
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },
  metaInfo() {
    return {
      title: [this.title, this.subtitle].join(' ')
    };
  }
};
</script>
